<template>
  <main class="dashboard">
    <p class="title">{{ shopName }}: {{ contractPlan }}</p>
    <p class="title">店家數據分析</p>
    <div class="time-picker-container">
      <el-date-picker
        v-model="dateRange"
        editable
        type="daterange"
        format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="開始日期"
        end-placeholder="結束日期"
        @change="changeTimeRange"
      />
    </div>

    <div v-loading="reload" class="big-result-container">
      <div class="result">
        <span class="result-title">新增預約訂單數</span>
        <div>
          <span class="result-number">{{ totalReservationCount }}</span>
        </div>
      </div>

      <el-divider direction="vertical" class="h-full" />

      <div class="result">
        <span class="result-title">新增會員數</span>
        <div>
          <span class="result-number">{{ totalMemberCount }}</span>
        </div>
      </div>

      <el-divider direction="vertical" class="h-full" />

      <div class="result">
        <span class="result-title">新增訂單數</span>
        <div>
          <span class="result-number">{{ totalOrderCount }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="title">
      <span class="title-hint">|</span>
      <span>店名新增預約數</span>
    </div>

    <section class="section-container" ref="container-1">
      <div
        v-if="!reload && reservationChartData.datasets[0].data.length"
        style="width: 100%"
      >
        <LineChart
          :width="containerWidth()"
          :height="400"
          :data="reservationChartData"
          :options="chartOptions"
        />
      </div>

      <EmptyBlock v-if="!reservationChartData.datasets[0].data.length" />
    </section> -->

    <!-- <div class="title">
      <span class="title-hint">|</span>
      <span>店名新增會員數</span>
    </div> -->
    <section ref="container-1" class="section-container" @resize="containerWidth()">
      <div
        v-if="!reload && chartData.datasets[0].data.length && !chartRendering"
        style="width: 100%;"
        class="chart-wrapper"
      >
        <!-- <span class="y-unit">[y]</span>
        <span class="x-unit">[x]</span> -->
        <LineChart
          style="padding-top: 5px"
          :width="containerWidth()"
          :height="400"
          :data="chartData"
          :options="chartOptions"
        />
      </div>

      <EmptyBlock v-if="!chartData.datasets[0].data.length" />
    </section>
  </main>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import LineChart from '@/components/LineChart.js'
import {
  AppointmentReservationData,
  CreatedMemberData,
  EcommerceOrderData,
} from '@/api/dashboard'
import { GetShopById } from '@/api/shop'
import { mapGetters } from 'vuex'
import { map, sum, find } from 'lodash'
import dayjs from '@/lib/dayjs'

export default {
  name: 'Dashboard',
  components: { LineChart, EmptyBlock },

  data: () => ({
    reload: false,
    chartRendering: false,
    contractPlan: '',
    dateRange: [
      dayjs().subtract(3, 'month').startOf('day').toDate(),
      dayjs().endOf('day').toDate(),
    ],
    chartData: {
      labels: [],
      datasets: [
        {
          label: '預約新增',
          data: [],
          key: 'test',
          fill: false,
          borderColor: '#2BB8C1',
          tension: 0.3,
        },
        {
          label: '會員新增',
          data: [],
          key: 'test',
          fill: false,
          borderColor: '#D8749C',
          tension: 0.3,
        },
        {
          label: '訂單新增',
          data: [],
          key: 'test',
          fill: false,
          borderColor: '#ffdd61',
          tension: 0.3,
        },
      ],
    },
    reservationChartData: {
      labels: [],
      datasets: [
        {
          label: '預約',
          data: [],
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    },
    ecommerceOrderChartData: {
      labels: [],
      datasets: [
        {
          label: '訂單',
          data: [],
          fill: false,
          borderColor: 'rgb(55, 90, 120)',
          tension: 0.1,
        },
      ],
    },
    chartOptions: {
      responsive: false,
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '數量',
              fontSize: 16,
            },
            display: true,
            ticks: {
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: '日期',
              fontSize: 16,
            },
            display: true,
            ticks: {
              beginAtZero: true, // minimum value will be 0.
            },
          },
        ],
      },
    },
  }),

  computed: {
    ...mapGetters(['shop', 'sidebar', 'shopName']),

    totalMemberCount () {
      return sum(this.chartData.datasets[1].data)
    },
    totalReservationCount () {
      return sum(this.chartData.datasets[0].data)
    },
    totalOrderCount () {
      return sum(this.chartData.datasets[2].data)
    },
  },
  watch: {
    sidebar () {
      this.reloadChart()
    },
  },
  async mounted () {
    // this.dateRange[0] = dayjs().subtract(3, 'month').toDate()
    // this.dateRange[1] = new Date()
    const docStyle = getComputedStyle(document.documentElement)
    const setColor = (dataSet, label, colorNumber) => {
      const target = find(dataSet, { label })
      target.borderColor = docStyle.getPropertyValue(`--chart-${colorNumber}`)
    }

    setColor(this.chartData.datasets, '預約新增', 1)
    setColor(this.chartData.datasets, '會員新增', 2)
    setColor(this.chartData.datasets, '訂單新增', 3)
    setColor(this.reservationChartData.datasets, '預約', 1)
    setColor(this.ecommerceOrderChartData.datasets, '訂單', 2)

    await this.fingShop()
    await this.changeTimeRange(true)
    const that = this
    window.onresize = () => that.reloadChart()
  },
  methods: {
    containerWidth () {
      return this.$refs['container-1']
        ? this.$refs['container-1'].clientWidth - 40
        : 400
    },
    reloadChart () {
      this.chartRendering = true
      setTimeout(() => {
        this.chartRendering = false
      }, 300)
    },
    async fingShop () {
      // const shop = await GetShopById(this.shop)
      // this.contractPlan = shop.ContractPlan?.name
      const [res, err] = await GetShopById(this.shop)
      if (err) return this.$message.error(err || err.msg)
      this.contractPlan = res.ContractPlan?.name
    },
    async appointmentReservationData () {
      const [res, err] = await AppointmentReservationData({
        shopId: this.shop,
        start: this.dateRange[0],
        end: dayjs(this.dateRange[1]).add(1, 'd').toDate(),
        splitType: 'week',
      })

      if (err) return this.$message.error(err)
      this.reservationChartData.labels = map(
        res,
        (item) => dayjs(item.start).format('YYYY-MM-DD'),
      )
      this.chartData.datasets[0].data = map(res, (item) => item.count)
    },

    async createdMemberData () {
      const [res, err] = await CreatedMemberData({
        shopId: this.shop,
        start: this.dateRange[0],
        end: dayjs(this.dateRange[1]).add(1, 'd').toDate(),
        splitType: 'week',
      })

      if (err) return this.$message.error(err)

      this.chartData.labels = map(
        res,
        (item) => dayjs(item.start).format('YYYY-MM-DD'),
      )
      this.chartData.datasets[1].data = map(res, (item) => item.count)
    },

    async ecommerceOrderData () {
      const [res, err] = await EcommerceOrderData({
        shopId: this.shop,
        start: this.dateRange[0],
        end: dayjs(this.dateRange[1]).add(1, 'd').toDate(),
        splitType: 'week',
      })

      if (err) return this.$message.error(err)

      this.chartData.labels = map(
        res,
        (item) => dayjs(item.start).format('YYYY-MM-DD'),
      )
      this.chartData.datasets[2].data = map(res, (item) => item.count)
    },

    async changeTimeRange (e) {
      if (!e) {
        console.log('in')
        this.chartData.datasets[0].data = []
        this.chartData.datasets[1].data = []
        return
      }
      this.reload = true
      await Promise.all([
        this.appointmentReservationData(),
        this.createdMemberData(),
        this.ecommerceOrderData(),
      ])
      // this.createdMemberData()
      // this.appointmentReservationData()
      // this.ecommerceOrderData()

      this.reload = false
    },
  },
}
</script>

<style>
.el-date-range-picker__editor.el-input.el-input--small {
  width: auto !important;
}
</style>

<style scoped lang="scss">
.time-picker-container {
  @apply mb-[24px];
}
.section-container {
  @apply bg-white h-[400px] flex justify-center items-center;
}

.title {
  @apply text-[24px] my-[30px];

  .sub {
    @apply text-[13px] text-[#666];
  }
}

.title-hint {
  @apply text-primary-100 mr-[5px];
}

.big-result-container {
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-bottom: 20px;
  background: white;

  .result {
    margin: 2px 0;
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .result-number {
    @apply text-[24px] text-primary-100 font-bold;
  }
}

// .chart-wrapper {
//   @apply relative;

//   .y-unit {
//     @apply absolute top-0 left-[5px];
//   }

//   .x-unit {
//     @apply absolute right-[10px] bottom-[5px];
//   }
// }

</style>
